import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useAtom } from 'jotai'
import axios from 'axios'
import Layout from 'components/Layout'
import Title from 'components/Title'
import Field from 'components/Field'
import Paragraph from 'components/Paragraph'
import Button from 'components/Button'
import { isNotEmpty, isEmailAddress } from '../utils/input-validation-utils'
import { alternateLanguagesState } from '../atoms/languageSwitcher'
import languages from '../i18n'
import contactSideImage from 'assets/images/contact-side.svg'

const ContactPage = () => {
  const intl = useIntl()
  const [alternateLanguages, setAlternateLanguages] = useAtom(alternateLanguagesState)
  const [userTypeOptions, setUserTypeOptions] = useState([])
  const [showErrors, setShowErrors] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [tel, setTel] = useState('')
  const [type, setType] = useState(userTypeOptions[0])
  const [message, setMessage] = useState('')
  const [isSent, setIsSent] = useState(false)

  useEffect(() => {
    setAlternateLanguages(languages.map((language) => ({
      lang: language.prismicLocale,
      skipResolver: true,
      url: `${language.prefix}/${language.slugs.contact}`
    })))
  }, [])

  useEffect(() => {
    const options = [
      { label: intl.formatMessage({ id: 'contact.user_type_company' }), value: 'entreprise' },
      { label: intl.formatMessage({ id: 'contact.user_type_individual' }), value: 'particulier' }
    ]

    setUserTypeOptions(options)
    setType(options[0])
  }, [intl])

  const onSubmit = () => {
    if (!isNotEmpty(name) || !isEmailAddress(email) || !isNotEmpty(message)) {
      setShowErrors(true)
    } else {
      setShowErrors(false)

      axios.post('https://submit-form.com/YyX30O3e', {
        message,
        name,
        tel,
        type: type.value,
        email
      })
        .then(() => setIsSent(true))
        .catch((err) => console.error(err))
    }
  }

  return (
    <Layout pageName='ContactPage'>
      <Helmet>
        <title>Assurimo - { intl.formatMessage({ id: 'contact.title' }) }</title>
      </Helmet>
      <section className='ContactPage-content'>
        <div className='ContactPage-left'>
          <div className='ContactPage-leftContent'>
            <Title tag='h2' className='ContactPage-title'>{ intl.formatMessage({ id: 'contact.title' }) }</Title>
            {!isSent && (
              <form className='ContactPage-form'>
                <Field
                  type='text'
                  label={ intl.formatMessage({ id: 'contact.name_label' }) }
                  placeholder={ intl.formatMessage({ id: 'contact.name_placeholder' }) }
                  name='name'
                  value={ name }
                  valid={ isNotEmpty(name) }
                  showErrors={ showErrors }
                  onChange={ (e) => setName(e.target.value) }
                />
                <Field
                  type='email'
                  label={ intl.formatMessage({ id: 'contact.email_label' }) }
                  placeholder={ intl.formatMessage({ id: 'contact.email_placeholder' }) }
                  name='email'
                  value={ email }
                  valid={ isEmailAddress(email) }
                  showErrors={ showErrors }
                  onChange={ (e) => setEmail(e.target.value) }
                />
                <Field
                  type='tel'
                  label={ intl.formatMessage({ id: 'contact.phone_label' }) }
                  placeholder={ intl.formatMessage({ id: 'contact.phone_placeholder' }) }
                  name='phone'
                  value={ tel }
                  onChange={ (e) => setTel(e.target.value) }
                />
                <Field
                  type='select'
                  label={ intl.formatMessage({ id: 'contact.user_type_label' }) }
                  options={ userTypeOptions }
                  name='type'
                  value={ type }
                  onChange={ (value) => setType(value) }
                />
                <Field
                  type='textarea'
                  label={ intl.formatMessage({ id: 'contact.message_label' }) }
                  placeholder={ intl.formatMessage({ id: 'contact.message_placeholder' }) }
                  name='message'
                  value={ message }
                  valid={ isNotEmpty(message) }
                  showErrors={ showErrors }
                  onChange={ (e) => setMessage(e.target.value) }
                  fullWidth
                />
                <Button style='yellow' onClick={ onSubmit } className='ContactPage-submit'>{ intl.formatMessage({ id: 'contact.send_button' }) }</Button>
              </form>
            )}
            {isSent && (
              <div className='ContactPage-sentMessage'>
                <Paragraph className='ContactPage-sentMessageText' color='darkBlue'>{ intl.formatMessage({ id: 'contact.message_sent' }) }</Paragraph>
                <Button to='/'>{ intl.formatMessage({ id: 'contact.home_cta' }) }</Button>
              </div>

            )}
          </div>
        </div>
        <div className='ContactPage-right'>
          <img className='ContactPage-image' src={ contactSideImage } />
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage
