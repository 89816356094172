import React, { useState } from 'react'
import Select from 'react-select'

const customSelectStyles = {
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    color: '#F37020'
  }),
  container: (provided) => ({
    ...provided,
    height: 49
  }),
  control: (provided) => ({
    ...provided,
    height: '100%',
    border: '1px solid rgba(0, 43, 104, 0.2)',
    boxShadow: 'none',
    '&:hover': {
      borderColor: 'rgba(0, 43, 104, 0.2)'
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: 'inherit',
    color: '#002B68',
    fontWeight: 'bold',
    fontSize: '1.5rem'
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#002B68' : '#000000',
    backgroundColor: state.isSelected ? 'rgba(210, 227, 250, 0.3)' : '#FFFFFF',
    fontWeight: 'bold',
    '&:active': {
      backgroundColor: state.isSelected ? 'rgba(210, 227, 250, 0.3)' : '#FFFFFF'
    }
  })
}

const Field = ({
  label,
  type,
  placeholder = '',
  name = '',
  autoComplete = 'off',
  autoCorrect = 'off',
  fullWidth = false,
  options,
  value,
  valid = true,
  showErrors = false,
  onChange = () => {}
}) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false)

  const inputAttributes = {
    placeholder,
    name,
    autoComplete,
    autoCorrect,
    id: `input-${name}`
  }

  return (
    <div className={ `Field Field--${type} ${fullWidth && 'Field--fullWidth'} ${!valid && showErrors && 'Field--invalid'}` }>
      <label className='Field-label' htmlFor={ inputAttributes.id }>
        { label }
      </label>

      {type === 'textarea' && (
        <textarea
          className='Field-input'
          value={ value }
          onChange={ onChange }
          { ...inputAttributes }
        />
      )}

      {(type === 'text' || type === 'email' || type === 'tel') && (
        <input
          className='Field-input'
          type={ type }
          value={ value }
          onChange={ onChange }
          { ...inputAttributes }
        />
      )}

      {(type === 'select') && (
        <Select
          className={ `Field-dropdown ${isSelectOpen ? 'Field-dropdown--open' : ''}` }
          options={ options }
          isSearchable={ false }
          hideSelectedOptions
          value={ value }
          onChange={ onChange }
          onMenuOpen={ () => { setIsSelectOpen(true) } }
          onMenuClose={ () => { setIsSelectOpen(false) } }
          styles={ customSelectStyles }
        />
      )}

      <label className='Field-errorIcon icon-info' htmlFor={ inputAttributes.id } />
    </div>
  )
}

export default Field
